import elec1 from "../images/elec1.jpg"
import elec2 from "../images/elec2.jpg"
import elec3 from "../images/elec3.jpg"
import elec4 from "../images/elec4.jpg"
import limp1 from "../images/limp3.png"
import limp2 from "../images/limp2.jpg"
import limp3 from "../images/limp1.jpg"
import limp4 from "../images/limp4.jpeg"
import carp1 from "../images/alu3.jpg"
import carp2 from "../images/alu1.jpg"
import carp3 from "../images/carp3.jpg"
import carp4 from "../images/alu2.jpg"
import pint1 from "../images/pintu1.jpg"
import pint2 from "../images/pint2.jpeg"
import pint3 from "../images/pint3.jpg"
import pint4 from "../images/pint4.jpg"
import pal1 from "../images/pale1.jpg"
import pal2 from "../images/pal2.jpg"
import pal3 from "../images/pal3.jpg"
import pal4 from "../images/pal4.jpg"
import mud1 from "../images/mud1.jpeg"
import mud2 from "../images/mud2.png"
import mud3 from "../images/mud3.jpg"
import fonta1 from "../images/fonta1.jpg"
import fonta2 from "../images/fonta2.jpg"
import fonta3 from "../images/fonta3.webp"
import fonta4 from "../images/fonta4.jpg"
import info1 from "../images/info3.jpg"
import info2 from "../images/info4.jpg"
import info3 from "../images/info5.jpg"

export const cardData = [
    {
        id: 1,
        image: elec1,
        collection: "ELECTRICIDAD",
        lng: "es",
        name_es: "ENCHUFES",
        name_cat: "ENDOLLS",
        name_en: "PLUGS",
        desc_es: "Colocamos todo tipo de enchufes, ya sean enchufes de superficie o tomas de corriente empotradas.",
        desc_cat: "Col·loquem tot tipus d'endolls, ja siguin endolls de superfície o preses de corrent encastades.",
        desc_en: "We install all types of plugs, whether surface plugs or built-in outlets.",
    },
    {
        id: 2,
        image: elec2,
        collection: "ELECTRICIDAD",
        lng: "es",
        name_es: "LAMPARAS",
        name_cat: "LLUMS",
        name_en: "LIGHTS",
        desc_es: "Instalamos todas las lámparas de pared o de techo que existen en el mercado, incluso en exteriores.",
        desc_cat: "Instal·lem tots els llums de paret o de sostre que hi ha al mercat, fins i tot a exteriors.",
        desc_en: "We install all the wall or ceiling lamps that exist on the market, even outdoors.",
    },
    {
        id: 3,
        image: elec3,
        collection: "ELECTRICIDAD",
        lng: "es",
        name_es: "CUADROS ELECTRICOS",
        name_cat: "QUADRES ELÈCTRICS",
        name_en: "ELECTRIC PANELS",
        desc_es: "Somos expertos en el montaje y cableado de cuadros de distribución, garantizando siempre la seguridad al 100%.",
        desc_cat: "Som experts en el muntatge i cablejat de quadres de distribució, garantint sempre la seguretat al 100%.",
        desc_en: "We are experts in the assembly and wiring of distribution panels, always guaranteeing 100% safety.",
    },
    {
        id: 19,
        image: elec4,
        collection: "ELECTRICIDAD",
        lng: "es",
        name_es: "PUNTOS DE LUZ",
        name_cat: "PUNTS DE LLUM",
        name_en: "LIGHT POINTS",
        desc_es: "Instalamos todos los puntos de luz que su hogar necesite y realizamos instalaciones de luz conmutadas.",
        desc_cat: "Instal·lem tots els punts de llum que la vostra llar necessiti i realitzem instal·lacions de llum commutades.",
        desc_en: "We install all the light points that your home needs and carry out switched light installations.",
    },
    {
        id: 4,
        image: limp1,
        collection: "LIMPIEZA",
        lng: "es",
        name_es: "PISOS",
        name_cat: "PISOS",
        name_en: "APPARTMENTS",
        desc_es: "Limpieza profesional a fondo o vaciado de pisos y casas, habitadas o en alquiler, en una sola jornada.",
        desc_cat: "Neteja professional a fons o buidatge de pisos i cases, habitades o de lloguer, en una sola jornada.",
        desc_en: "Thorough professional cleaning or emptying of flats and houses, inhabited or rented, in a single day.",
    },
    {
        id: 5,
        image: limp2,
        collection: "LIMPIEZA",
        lng: "es",
        name_es: "OFICINAS",
        name_cat: "OFICINES",
        name_en: "OFFICES",
        desc_es: "Nos adaptamos a los horarios y necesidades de su oficina para garantizar una limpieza y desinfección absoluta.",
        desc_cat: "Ens adaptem als horaris i necessitats de la vostra oficina per garantir una neteja i desinfecció absoluta.",
        desc_en: "We adapt to the schedules and needs of your office to guarantee absolute cleaning and disinfection.",
    },
    {
        id: 6,
        image: limp3,
        collection: "LIMPIEZA",
        lng: "es",
        name_es: "LOCALES",
        name_cat: "LOCALS",
        name_en: "COMMERCIAL PREMISES",
        desc_es: "Gracias a nuestro servicio de limpieza 24 horas podemos ocuparnos de tu local a cualquier hora del día.",
        desc_cat: "Gràcies al nostre servei de neteja 24 hores ens podem ocupar del teu local a qualsevol hora del dia.",
        desc_en: "Thanks to our 24-hour cleaning service we can take care of your premises at any time of the day.",
    },
    {
        id: 20,
        image: limp4,
        collection: "LIMPIEZA",
        lng: "es",
        name_es: "EMPRESAS",
        name_cat: "EMPRESES",
        name_en: "COMPANIES",
        desc_es: "Ofrecemos servicios por horas o plantilla fija diaria para la limpieza y desinfección de su empresa o negocio.",
        desc_cat: "Oferim serveis per hores o plantilla fixa diària per a la neteja i desinfecció de la vostra empresa o negoci.",
        desc_en: "We offer hourly services or a fixed daily workforce for the cleaning and disinfection of your company or business.",
    },
    {
        id: 7,
        image: carp1,
        collection: "CARPINTERÍA",
        lng: "es",
        name_es: "MONTAJE DE MUEBLES",
        name_cat: "MUNTATGE DE MOBLES",
        name_en: "FURNITURE ASSEMBLY",
        desc_es: "Te ayudamos con el transporte o diseñamos y montamos todos los muebles de tu casa, oficina, local o negocio.",
        desc_cat: "T'ajudem amb el transport o dissenyem i muntem tots els mobles de casa teva, oficina, local o negoci.",
        desc_en: "We help you with transportation or we design and assemble all the furniture for your home, office, premises or business.",
    },
    {
        id: 8,
        image: carp2,
        collection: "CARPINTERÍA",
        lng: "es",
        name_es: "INSTALACIONES",
        name_cat: "INSTAL·LACIONS",
        name_en: "SETTING UP",
        desc_es: "Diseñamos y realizamos instalaciones de todo tipo de estructuras en madera y aluminio.",
        desc_cat: "Dissenyem i realitzem instal·lacions de tota mena d'estructures de fusta i alumini.",
        desc_en: "We design and carry out installations of all types of structures in wood and aluminum.",
    },
    {
        id: 9,
        image: carp3,
        collection: "CARPINTERÍA",
        lng: "es",
        name_es: "REPARACIONES",
        name_cat: "REPARACIONS",
        name_en: "REPAIRS",
        desc_es: "Mantenemos la madera de tus muebles o reparamos aquella que haya sido dañada por agentes externos.",
        desc_cat: "Mantenim la fusta dels teus mobles o reparem aquella que hagi estat malmesa per agents externs.",
        desc_en: "We maintain the wood of your furniture or repair wood that has been damaged by external agents.",
    },
    {
        id: 21,
        image: carp4,
        collection: "CARPINTERÍA",
        lng: "es",
        name_es: "PUERTAS",
        name_cat: "PORTES",
        name_en: "DOORS",
        desc_es: "Instalamos o renovamos tus puertas por puertas de madera sólida, de núcleo sólido, de núcleo hueco o de aluminio.",
        desc_cat: "Instal·lem o renovem les portes per portes de fusta sòlida, de nucli sòlid, de nucli buit o d'alumini.",
        desc_en: "We install or renew your doors with solid wood doors, solid core doors, hollow core doors or aluminum doors.",
    },
    {
        id: 10,
        image: pint1,
        collection: "PINTURA",
        lng: "es",
        name_es: "INTERIORES",
        name_cat: "INTERIORS",
        name_en: "INDOORS",
        desc_es: "Andrade Serveis ofrece un amplio abanico de estilos, diseños, texturas, efectos y colores para el interior.",
        desc_cat: "Andrade Serveis ofereix un ampli ventall d'estils, dissenys, textures, efectes i colors per a l'interior.",
        desc_en: "Andrade Serveis offers a wide range of styles, designs, textures, effects and colors for the interior.",
    },
    {
        id: 11,
        image: pint2,
        collection: "PINTURA",
        lng: "es",
        name_es: "EXTERIORES",
        name_cat: "EXTERIORS",
        name_en: "OUTDOORS",
        desc_es: "Especialistas en revestimientos pétreos o acrílicos para la durabilidad y protección de sus exteriores.",
        desc_cat: "Especialistes en revestiments petris o acrílics per a la durabilitat i protecció dels seus exteriors.",
        desc_en: "Specialists in stone or acrylic coatings for the durability and protection of your exteriors.",
    },
    {
        id: 12,
        image: pint3,
        collection: "PINTURA",
        lng: "es",
        name_es: "ESTUCADOS",
        name_cat: "ESTUCATS",
        name_en: "COATED",
        desc_es: "Conseguimos el mejor acabado para sus paredes con nuestro catálogo de estucados, tanto plásticos como acrílicos.",
        desc_cat: "Aconseguim el millor acabat per a les parets amb el nostre catàleg d'estucats, tant plàstics com acrílics.",
        desc_en: "We achieve the best finish for your walls with our catalog of stuccoes, both plastic and acrylic.",
    },
    {
        id: 27,
        image: pint4,
        collection: "PINTURA",
        lng: "es",
        name_es: "ALISADOS",
        name_cat: "ALLISATS",
        name_en: "STRAIGHTENING",
        desc_es: "Reparamos y alisamos paredes dañadas por grietas, humedades o con gotelé para que puedan ser pintadas.",
        desc_cat: "Reparem i allisem parets danyades per esquerdes, humitats o amb gotelé perquè puguin ser pintades.",
        desc_en: "We repair and smooth walls damaged by cracks, humidity or dripping so that they can be painted.",
    },
    {
        id: 13,
        image: pal1,
        collection: "PALETERÍA",
        lng: "es",
        name_es: "BAÑOS",
        name_cat: "BANYS",
        name_en: "BATHROOMS",
        desc_es: "Cambiamos bañeras por platos de ducha y instalamos o reformamos tu baño para que una funcionalidad y elegancia.",
        desc_cat: "Canviem banyeres per plats de dutxa i instal·lem o reformem el teu bany perquè una funcionalitat i elegància.",
        desc_en: "We change bathtubs for shower trays and install or renovate your bathroom for functionality and elegance.",
    },
    {
        id: 14,
        image: pal2,
        collection: "PALETERÍA",
        lng: "es",
        name_es: "COCINAS",
        name_cat: "CUINES",
        name_en: "KITCHENS",
        desc_es: "Asesoramos, realizamos el transporte y la instalación de todos los elementos de la cocina de tus sueños.",
        desc_cat: "Assessorem, realitzem el transport i la instal·lació de tots els elements de la cuina dels teus somnis.",
        desc_en: "We advise, carry out the transport and installation of all the elements of the kitchen of your dreams.",
    },
    {
        id: 15,
        image: pal3,
        collection: "PALETERÍA",
        lng: "es",
        name_es: "SUELOS",
        name_cat: "TERRES",
        name_en: "FLOORS",
        desc_es: "Somos expertos en la reforma o instalación de suelos de baldosa, laminados, de resina epoxi, de vinilo y pintados.",
        desc_cat: "Som experts en la reforma o instal·lació de terres de rajola, laminats, de resina epoxi, de vinil i pintats.",
        desc_en: "We are experts in the renovation or installation of tile, laminate, epoxy resin, vinyl and painted floors.",
    },
    {
        id: 22,
        image: pal4,
        collection: "PALETERÍA",
        lng: "es",
        name_es: "TERRAZAS",
        name_cat: "TERRASSES",
        name_en: "TERRACES",
        desc_es: "Diseñamos y construimos terrazas en múltiples materiales para renovar el jardín de tu casa.",
        desc_cat: "Dissenyem i construïm terrasses en múltiples materials per renovar el jardí de casa teva.",
        desc_en: "We design and build terraces in multiple materials to renew the garden of your home.",
    },
    {
        id: 16,
        image: mud1,
        collection: "MUDANZAS",
        lng: "es",
        name_es: "PORTES",
        name_cat: "PORTS",
        name_en: "SHIPPING",
        desc_es: "Servicios rápidos y seguros para realizar traslados de urgencia en el menor tiempo posible.",
        desc_cat: "Serveis ràpids i segurs per fer trasllats d'urgència en el menor temps possible.",
        desc_en: "Fast and safe services to carry out emergency transfers in the shortest time possible.",
    },
    {
        id: 17,
        image: mud2,
        collection: "MUDANZAS",
        lng: "es",
        name_es: "EXPRESS",
        name_cat: "EXPRESS",
        name_en: "EXPRESS",
        desc_es: "Servicios rápidos y seguros para realizar traslados de urgencia en el menor tiempo posible.",
        desc_cat: "Serveis ràpids i segurs per fer trasllats d'urgència en el menor temps possible.",
        desc_en: "Fast and safe services to carry out emergency transfers in the shortest time possible.",
    },
    {
        id: 18,
        image: mud3,
        collection: "MUDANZAS",
        lng: "es",
        name_es: "MUDANZA COMPLETA",
        name_cat: "MUDANÇA COMPLETA",
        name_en: "COMPLETE MOVE",
        desc_es: "Estamos especializados en servicios de mudanzas en toda la provincia de Barcelona para particulares y empresas.",
        desc_cat: "Estem especialitzats en serveis de mudances a tota la província de Barcelona per a particulars i empreses.",
        desc_en: "We are specialized in moving services throughout the province of Barcelona for individuals and companies.",
    },
    {
        id: 23,
        image: fonta2,
        collection: "FONTANERÍA",
        lng: "es",
        name_es: "FUGAS",
        name_cat: "FUITES",
        name_en: "LEAKS",
        desc_es: "Especialistas en detección y reparación de fugas de agua de la forma más rápida y económica.",
        desc_cat: "Especialistes en detecció i reparació de fuites daigua de la forma més ràpida i econòmica.",
        desc_en: "Specialists in detecting and repairing water leaks in the fastest and most economical way.",
    },
    {
        id: 24,
        image: fonta3,
        collection: "FONTANERÍA",
        lng: "es",
        name_es: "DESAGÜES",
        name_cat: "DESAGÜES",
        name_en: "DRAINS",
        desc_es: "Instalamos los desagües que necesiten los electrodomésticos y utensilios de su cocina y baño.",
        desc_cat: "Instal·lem els desguassos que necessitin els electrodomèstics i utensilis de la seva cuina i bany.",
        desc_en: "We install the drains that your kitchen and bathroom appliances and utensils need.",
    },
    {
        id: 25,
        image: fonta4,
        collection: "FONTANERÍA",
        lng: "es",
        name_es: "TUBOS DE COBRE",
        name_cat: "TUBS DE COURE",
        name_en: "COPPER TUBES",
        desc_es: "Instalamos o reemplazamos los tubos de cobre de fontanería, la calefacción o el gas de su hogar o empresa.",
        desc_cat: "Instal·lem o reemplacem els tubs de coure de fontaneria, la calefacció o el gas de casa seva o empresa.",
        desc_en: "We install or replace copper plumbing, heating or gas pipes in your home or business.",
    },
    {
        id: 26,
        image: fonta1,
        collection: "FONTANERÍA",
        lng: "es",
        name_es: "CALEFACCIÓN",
        name_cat: "CALEFACCIÓ",
        name_en: "HEATING",
        desc_es: "Realizamos la instalación de la calefacción en su hogar o empresa, ya sea de gas, eléctrica o de gasoil.",
        desc_cat: "Realitzem la instal·lació de la calefacció a casa seva o empresa, ja sigui de gas, elèctrica o de gasoil.",
        desc_en: "We install heating in your home or business, whether gas, electric or diesel.",
    },
    {
        id: 28,
        image: info1,
        collection: "INFORMÁTICA",
        lng: "es",
        name_es: "DISEÑO WEB",
        name_cat: "DISENY WEB",
        name_en: "WEB DESIGN",
        desc_es: "Nos especializamos en el diseño de páginas web con un diseño moderno, funcional y profesional.",
        desc_cat: "Som especialistes en el disseny de pàgines web amb un disseny modern, funcional i professional.",
        desc_en: "We specialize in web page design with a modern, functional and professional design.",
    },
    {
        id: 29,
        image: info2,
        collection: "INFORMÁTICA",
        lng: "es",
        name_es: "POSICIONAMIENTO",
        name_cat: "POSICIONAMIENT",
        name_en: "POSITIONING",
        desc_es: "Con nuestro servicio SEO nos encargamos de que su página Web aparezca en las primeras posiciones de Google.",
        desc_cat: "Amb el nostre servei SEO ens encarreguem que la seva pàgina web aparegui a les primeres posicions de Google.",
        desc_en: "With our SEO service we ensure that your website appears in the top positions on Google.",
    },
    {
        id: 30,
        image: info3,
        collection: "INFORMÁTICA",
        lng: "es",
        name_es: "SERVICIO TÉCNICO",
        name_cat: "SERVEI TÈCNIC",
        name_en: "TECHNICAL SUPPORT",
        desc_es: "Solucionamos cualquier problema de instalación y configuración de SSOO, software y componentes.",
        desc_cat: "Solucionem qualsevol problema d'instal·lació i configuració de SSOO, software i components.",
        desc_en: "We solve any SSOO installation and configuration problem, or in software and components.",
    }/* ,
    {
        id: 31,
        image: elec1,
        collection: "ELECTRICIDAD",
        lng: "cat",
        name: "ENDOLLS",
        desc: "Col·loquem tot tipus d'endolls, ja siguin endolls de superfície o preses de corrent encastades.",
    },
    {
        id: 32,
        image: elec2,
        collection: "ELECTRICIDAD",
        lng: "cat",
        name: "LLUMS",
        desc: "Instal·lem tots els llums de paret o de sostre que hi ha al mercat, fins i tot a exteriors.",
    },
    {
        id: 33,
        image: elec3,
        collection: "ELECTRICIDAD",
        lng: "cat",
        name: "QUADRES ELÈCTRICS",
        desc: "Som experts en el muntatge i cablejat de quadres de distribució, garantint sempre la seguretat al 100%.",
    },
    {
        id: 34,
        image: elec4,
        collection: "ELECTRICIDAD",
        lng: "cat",
        name: "PUNTS DE LLUM",
        desc: "Instal·lem tots els punts de llum que la vostra llar necessiti i realitzem instal·lacions de llum commutades.",
    },
    {
        id: 35,
        image: limp1,
        collection: "LIMPIEZA",
        lng: "cat",
        name: "PISOS",
        desc: "Neteja professional a fons o buidatge de pisos i cases, habitades o de lloguer, en una sola jornada.",
    },
    {
        id: 36,
        image: limp2,
        collection: "LIMPIEZA",
        lng: "cat",
        name: "OFICINES",
        desc: "Ens adaptem als horaris i necessitats de la vostra oficina per garantir una neteja i desinfecció absoluta.",
    },
    {
        id: 37,
        image: limp3,
        collection: "LIMPIEZA",
        lng: "cat",
        name: "LOCALS",
        desc: "Gràcies al nostre servei de neteja 24 hores ens podem ocupar del teu local a qualsevol hora del dia.",
    },
    {
        id: 38,
        image: limp4,
        collection: "LIMPIEZA",
        lng: "cat",
        name: "EMPRESES",
        desc: "Oferim serveis per hores o plantilla fixa diària per a la neteja i desinfecció de la vostra empresa o negoci.",
    },
    {
        id: 39,
        image: carp1,
        collection: "CARPINTERÍA",
        lng: "cat",
        name: "MUNTATGE DE MOBLES",
        desc: "T'ajudem amb el transport o dissenyem i muntem tots els mobles de casa teva, oficina, local o negoci.",
    },
    {
        id: 40,
        image: carp2,
        collection: "CARPINTERÍA",
        lng: "cat",
        name: "INSTAL·LACIONS",
        desc: "Dissenyem i realitzem instal·lacions de tota mena d'estructures de fusta i alumini.",
    },
    {
        id: 41,
        image: carp3,
        collection: "CARPINTERÍA",
        lng: "cat",
        name: "REPARACIONS",
        desc: "Mantenim la fusta dels teus mobles o reparem aquella que hagi estat malmesa per agents externs.",
    },
    {
        id: 42,
        image: carp4,
        collection: "CARPINTERÍA",
        lng: "cat",
        name: "PORTES",
        desc: "Instal·lem o renovem les portes per portes de fusta sòlida, de nucli sòlid, de nucli buit o d'alumini.",
    },
    {
        id: 43,
        image: pint1,
        collection: "PINTURA",
        lng: "cat",
        name: "INTERIORES",
        desc: "Andrade Serveis ofrece un amplio abanico de estilos, diseños, texturas, efectos y colores para el interior.",
    },
    {
        id: 44,
        image: pint2,
        collection: "PINTURA",
        lng: "cat",
        name: "EXTERIORES",
        desc: "Especialistas en revestimientos pétreos o acrílicos para la durabilidad y protección de sus exteriores.",
    },
    {
        id: 45,
        image: pint3,
        collection: "PINTURA",
        lng: "cat",
        name: "ESTUCADOS",
        desc: "Conseguimos el mejor acabado para sus paredes con nuestro catálogo de estucados, tanto plásticos como acrílicos.",
    },
    {
        id: 46,
        image: pint4,
        collection: "PINTURA",
        lng: "cat",
        name: "ALISADOS",
        desc: "Reparamos y alisamos paredes dañadas por grietas, humedades o con gotelé para que puedan ser pintadas.",
    },
    {
        id: 47,
        image: pal1,
        collection: "PALETERÍA",
        lng: "cat",
        name: "BAÑOS",
        desc: "Cambiamos bañeras por platos de ducha y instalamos o reformamos tu baño para que una funcionalidad y elegancia.",
    },
    {
        id: 48,
        image: pal2,
        collection: "PALETERÍA",
        lng: "cat",
        name: "COCINAS",
        desc: "Asesoramos, realizamos el transporte y la instalación de todos los elementos de la cocina de tus sueños.",
    },
    {
        id: 49,
        image: pal3,
        collection: "PALETERÍA",
        lng: "cat",
        name: "SUELOS",
        desc: "Somos expertos en la reforma o instalación de suelos de baldosa, laminados, de resina epoxi, de vinilo y pintados.",
    },
    {
        id: 50,
        image: pal4,
        collection: "PALETERÍA",
        lng: "cat",
        name: "TERRAZAS",
        desc: "Diseñamos y construimos terrazas en múltiples materiales para renovar el jardín de tu casa.",
    },
    {
        id: 51,
        image: mud1,
        collection: "MUDANZAS",
        lng: "cat",
        name: "PORTES",
        desc: "Servicios rápidos y seguros para realizar traslados de urgencia en el menor tiempo posible.",
    },
    {
        id: 52,
        image: mud2,
        collection: "MUDANZAS",
        lng: "cat",
        name: "EXPRESS",
        desc: "Servicios rápidos y seguros para realizar traslados de urgencia en el menor tiempo posible.",
    },
    {
        id: 53,
        image: mud3,
        collection: "MUDANZAS",
        lng: "cat",
        name: "MUDANZA COMPLETA",
        desc: "Estamos especializados en servicios de mudanzas en toda la provincia de Barcelona para particulares y empresas.",
    },
    {
        id: 54,
        image: fonta2,
        collection: "FONTANERÍA",
        lng: "cat",
        name: "FUGAS",
        desc: "Especialistas en detección y reparación de fugas de agua de la forma más rápida y económica.",
    },
    {
        id: 55,
        image: fonta3,
        collection: "FONTANERÍA",
        lng: "cat",
        name: "DESAGÜES",
        desc: "Instalamos los desagües que necesiten los electrodomésticos y utensilios de su cocina y baño.",
    },
    {
        id: 56,
        image: fonta4,
        collection: "FONTANERÍA",
        lng: "cat",
        name: "TUBOS DE COBRE",
        desc: "Instalamos o reemplazamos los tubos de cobre de fontanería, la calefacción o el gas de su hogar o empresa.",
    },
    {
        id: 57,
        image: fonta1,
        collection: "FONTANERÍA",
        lng: "cat",
        name: "CALEFACCIÓN",
        desc: "Realizamos la instalación de la calefacción en su hogar o empresa, ya sea de gas, eléctrica o de gasoil.",
    },
    {
        id: 58,
        image: info1,
        collection: "INFORMÁTICA",
        lng: "cat",
        name: "DISEÑO WEB",
        desc: "Nos especializamos en el diseño de páginas web con un diseño moderno, funcional y profesional.",
    },
    {
        id: 59,
        image: info2,
        collection: "INFORMÁTICA",
        lng: "cat",
        name: "POSICIONAMIENTO",
        desc: "Con nuestro servicio SEO nos encargamos de que su página Web aparezca en las primeras posiciones de Google.",
    },
    {
        id: 60,
        image: info3,
        collection: "INFORMÁTICA",
        lng: "cat",
        name: "SERVICIO TÉCNICO",
        desc: "Solucionamos cualquier problema de instalación y configuración de SSOO, software y componentes.",
    } */
    
]